import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr'
import axios from 'axios';


import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { STORAGE, APIS, ACTIONS } from './config/config';
//import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const token = localStorage.getItem(STORAGE);



function render() {
   
   
   ReactDOM.render(
      <Provider store={store}>
         <div className="height-full">
            <ReduxToastr
               timeOut={5000}
               newestOnTop={false}
               preventDuplicates
               position="top-center"
               getState={(state) => state.toastr} // This is the default
               transitionIn="fadeIn"
               transitionOut="fadeOut"
               progressBar
               closeOnToastrClick />
            <App />
         </div>
      </Provider>,
      document.getElementById('root'),
   );
}


   render();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
