/* eslint eqeqeq: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import NotFound from './pages/_common/page404';





import ScrollIntoView from './common/ScrollIntoView'
import Loader from './common/loader/loader';


// import PrivateRoute from './common/hoc/ProtectedRoute';
// import PublicRoute from './common/hoc/PublicRoute';




import Homepage from './pages/innerpages/homepage';
import Playerlisting from './pages/innerpages/playerlisting';
import Interestedplayerlist from './pages/innerpages/interestedplayerlist';

import Playerdetails from './pages/innerpages/playerdetails';
import Contact from './pages/innerpages/contact';
import Playermicro from './pages/innerpages/playermicro';





class App extends Component {

   render() {
      // let authed = this.props.authenticated
      // let user = this.props.user
      return (
         <Router>
            <div className="App height-full">
               <Loader />
               <>
                  <ScrollIntoView>
                     <Switch>
                        {/* <Route exact path="/:name" component={Homepage} />
                      
                        <Route exact path="/player/:name" component={Playerlisting} />


                        <Route exact path="/player/playerdatails/:name/:id" component={Playerdetails} />
                        <Route exact path="/player/playerdatails/contact/" component={Contact} /> */}

                     <Route exact path="/" component={Homepage} />                      
                      <Route exact path="/player/" component={Playerlisting} />
                      <Route exact path="/interestedplayerlist/" component={Interestedplayerlist} />

                      {/* <Route exact path="/player/:id" component={Playerlisting} />
                      <Route exact path="/playerdatails/:id" component={Playerdetails} />
                      <Route exact path="/contact/:playerId" component={Contact} /> */}
                      <Route exact path="/playermicro/:id" component={Playermicro} />
                       
                        <Route path="*" component={NotFound} />
                     </Switch>
                  </ScrollIntoView>
               </>
            </div>
         </Router>
      )
   }
}
const mapStateToProps = state => {
   return {
      user: state.user && state.user.user,
      authenticated: state.user.isAuthenticated,
      isProfileComplete: state.user.isProfileComplete,
   };
};

export default connect(mapStateToProps)(App);
