import React, { Component } from "react";
import { SAGA_ACTIONS, ACTIONS } from "../../config/config";
import { InputField } from "../../common/form-elements/elements";
import {
  required,
  emailValidator,
  textOnly,
  phoneValidator,
  passwordStrength,
  passwordsMatch,
  numbersOnly,
  usZipCodeValidator,
} from "../../common/validators/form-validators";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";

import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Header from "./header";

const errorStyle = {
  fontSize: "12px",
  color: "#f00",
  display: "inline-block",
};
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkList: [
        {
          name: "city",
          value: "bangalore",
        },
        {
          name: "city",
          value: "chennai",
        },
        {
          name: "city",
          value: "delhi",
        },
      ],
      formDocs: {},
    };
  }
  componentDidMount() {}

  handleChange2 = (e, name, value) => {
    let s = this.state.formDocs;
    if (e.target.checked) {
      let prevStr = s[name];

      if (!prevStr) {
        s[name] = e.target.value;
      } else {
        s[name] = [prevStr, e.target.value].join(",");
      }
    } else {
      let prevStr = s[name];
      s[name] = prevStr
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");
      this.setState({ formDocs: s });
    }

    console.log(">>>>>>>>>", s);
  };
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <Header/>

        <div id="homeBanner" className="carousel slide" data-bs-ride="false">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="carousel-caption">
                <div className="container">
                  <div className="row">
                    <div className="offset-lg-7 offset-md-7 col-lg-5 col-md-5">
                      
                      <div className="banner_logo">
                        <ul>
                          
                          <li>
                            {/* <a href="#">
                              <img src="images/logo_03.png" />
                            </a> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="viewplayer_btnarea">
          <div className="container">
            
            <NavLink to={"player/"} className="viewplayer_btn">
              Get started 
              </NavLink>
            
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            Copyright © College Athlete Advantage LLC.
          </div>
        </div>
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Homepage);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
