import React, { Component } from "react";
import { SAGA_ACTIONS, placehold } from "../../config/config";

import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";


import $ from "jquery";
import Header from "./header";
import moment from 'moment';


class Playerdetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      playerDetalsData: {},
      playerGameData: {},
      playeraboutdetails: {},
      playerimages: {},
      playerAcademicData: {},
      filedata: {},
      playervideo: {},
      playerSchedule: {},
    };
  }
  componentDidMount() {
    setTimeout(() => {
      $("#myTab a").on("click", function (e) {
        e.preventDefault();
        window.$(this).tab("show");
      });
    }, 700);

    this.getPlayerGameData();
  }
  getPlayerDetails = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({ playerDetalsData: res.data.data.details });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };

  getPlayerGameData = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_GAME_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({ playerDetalsData: res.data.data.player_details });
          this.setState({ playerGameData: res.data.data.player_details_game });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  getAboutData = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_ABOUT_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          console.log(">>>>>>bio>>>>", res.data.data);
          this.setState({ playerDetalsData: res.data.data.player_details });
          this.setState({
            playeraboutdetails: res.data.data.player_details_bio,
          });
          this.setState({ playerimages: res.data.data.player_images });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  getAcadamicData = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_ACADEMIC_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          console.log(">>>>>>bio>>>>", res.data.data);
          this.setState({ playerDetalsData: res.data.data.player_details });
          this.setState({
            playerAcademicData: res.data.data.player_details_academic,
          });
          this.setState({
            filedata: res.data.data.file_data,
          });

          this.setState({ playerimages: res.data.data.player_images });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  getVideoData = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_MEDIA_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          console.log(">>>>>>bio>>>>", res.data.data);
          this.setState({ playerimages: res.data.data.images });
          this.setState({ playervideo: res.data.data.videos });
          this.setState({ playerDetalsData: res.data.data.player_details });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  getScheduleList = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_SCHEDULE_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          console.log(">>>>>>schedule>>>>", res.data.data);
          this.setState({ playerimages: res.data.data.player_images });
          this.setState({ playerSchedule: res.data.data.player_schedule });
          this.setState({ playerDetalsData: res.data.data.player_details });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  render() {
    const { handleSubmit, submitting } = this.props;
    const userInitials =
      this.state.playerDetalsData && this.state.playerDetalsData.first_name
        ? this.state.playerDetalsData.first_name.charAt(0).toUpperCase()
        : "";
    return (
      <div>
        <Header />

        <div className="player_Listing playerDetails">
          <div className="container">
            <div className="playerlisting_bg">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="baseball-tab"
                    data-toggle="tab"
                    href="#baseball"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={(e) => this.getPlayerGameData()}
                  >
                    {this.state.playerDetalsData.game_name
                      ? this.state.playerDetalsData.game_name
                      : "NA"}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="academic-tab"
                    data-toggle="tab"
                    href="#academic"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    onClick={(e) => this.getAcadamicData()}
                  >
                    Academic
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="about-tab"
                    data-toggle="tab"
                    href="#about"
                    role="tab"
                    aria-controls="messages"
                    aria-selected="false"
                    onClick={(e) => this.getAboutData()}
                  >
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="media-tab"
                    data-toggle="tab"
                    href="#media"
                    role="tab"
                    aria-controls="cf"
                    aria-selected="false"
                    onClick={(e) => this.getVideoData()}
                  >
                    Media
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="schedule-tab"
                    data-toggle="tab"
                    href="#schedule"
                    role="tab"
                    aria-controls="lf"
                    aria-selected="false"
                    onClick={(e) => this.getScheduleList()}
                  >
                    Schedule
                  </a>
                </li>
              </ul>

              <div className="tab-content">
                <div
                  className="tab-pane active"
                  id="baseball"
                  role="tabpanel"
                  aria-labelledby="baseball-tab"
                >
                  <div className="year_listplayer p-0 mt-2">
                    <div className="baseballPlayer">
                      <h3>
                        {this.state.playerDetalsData.first_name}{" "}
                        {this.state.playerDetalsData.game_name} Stats
                      </h3>
                      <div className="player_list">
                        <div className="player_listimage">
                          <img
                            src={
                              this.state.playerDetalsData.profile_pic_url
                                ? this.state.playerDetalsData.profile_pic_url
                                : `${placehold}/400x400/?text=${userInitials}`
                            }
                            alt={this.state.playerDetalsData.first_name}
                          />
                        </div>
                        <div className="player_listtxt">
                          <ul>
                            <li>
                              Primary Position :{" "}
                              <span>
                                {" "}
                                {this.state.playerGameData.primary_position
                                  ? this.state.playerGameData.primary_position
                                  : ""}
                              </span>
                            </li>
                            <li>
                              Secondary Positions :{" "}
                              <span>
                                {this.state.playerGameData.secondary_position
                                  ? this.state.playerGameData.secondary_position
                                  : ""}
                              </span>
                            </li>
                            <li>
                              Height / Weight :{" "}
                              <span>
                                {this.state.playerGameData.height
                                  ? this.state.playerGameData.height
                                  : ""}{" "}
                                /{" "}
                                {this.state.playerGameData.weight
                                  ? this.state.playerGameData.weight
                                  : ""}
                              </span>
                            </li>
                            <li>
                              Bats / Throws :{" "}
                              <span>
                                {this.state.playerGameData.throws
                                  ? this.state.playerGameData.throws
                                  : ""}{" "}
                                /{" "}
                                {this.state.playerGameData.bats
                                  ? this.state.playerGameData.bats
                                  : ""}
                              </span>
                            </li>
                            <li className="coach">Coach Details</li>
                            <li>
                              Name :{" "}
                              <span>
                                {this.state.playerGameData.coach_name
                                  ? this.state.playerGameData.coach_name
                                  : ""}
                              </span>
                            </li>
                            <li>
                              Email :{" "}
                              <span>
                                {this.state.playerGameData.coach_email
                                  ? this.state.playerGameData.coach_email
                                  : ""}
                              </span>
                            </li>
                            <li>
                              Phone :{" "}
                              <span>
                                {this.state.playerGameData.coach_phone
                                  ? this.state.playerGameData.coach_phone
                                  : ""}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane"
                  id="academic"
                  role="tabpanel"
                  aria-labelledby="academic-tab"
                >
                  <div className="year_listplayer p-0 mt-2">
                    <div className="academic">
                      <h3>Academic</h3>
                   
                      <div className="player_list">
                        <div className="player_listtxt">
                          <ul>
                            <li className="coach">
                              {this.state.playerDetalsData.first_name}{" "}
                              {this.state.playerDetalsData.last_name}
                              <div className="classRanklist">
                                <ul>
                                  <li>
                                    GPA
                                    <span>
                                      {this.state.playerAcademicData
                                        .unweighted_gpa
                                        ? this.state.playerAcademicData
                                            .unweighted_gpa
                                        : ""}
                                    </span>
                                  </li>
                                  <li>
                                    ACT
                                    <span>
                                      {this.state.playerAcademicData
                                        .taken_the_acts
                                        ? this.state.playerAcademicData
                                            .taken_the_acts
                                        : ""}
                                    </span>
                                  </li>
                                  <li>
                                    SAT
                                    <span>
                                      {this.state.playerAcademicData
                                        .taken_the_sats
                                        ? this.state.playerAcademicData
                                            .taken_the_sats
                                        : ""}
                                    </span>
                                  </li>
                                  <li>
                                    CLASS RANK
                                    <span>
                                      {" "}
                                      {this.state.playerAcademicData.class_rank}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              High School Counselor :{" "}
                              <span>
                                {this.state.playerAcademicData.counselor_name}
                              </span>
                            </li>
                            <li>
                              Counselor Email :{" "}
                              <span>
                                {this.state.playerAcademicData.counselor_email}
                              </span>
                            </li>
                            <li>
                              Counselor Phone :{" "}
                              <span>
                                {this.state.playerAcademicData.counselor_phone}
                              </span>
                            </li>
                            <li>
                              High School Coach :{" "}
                              <span>
                                {this.state.playerAcademicData.coach_name}
                              </span>
                            </li>
                            <li>
                              Coach Email :{" "}
                              <span>
                                {this.state.playerAcademicData.coach_email}
                              </span>
                            </li>
                            <li>
                              Coach Phone :{" "}
                              <span>
                                {this.state.playerAcademicData.coach_phone}
                              </span>
                            </li>
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <li className="coach">ACADEMIC INFO</li>
                                <li>
                                  Unweighted Gpa{" "}
                                  <span>
                                    {
                                      this.state.playerAcademicData
                                        .unweighted_gpa
                                    }
                                  </span>
                                </li>
                                <li>
                                  Weighted Gpa{" "}
                                  <span>
                                    {this.state.playerAcademicData.weighted_gpa
                                      ? this.state.playerAcademicData
                                          .weighted_gpa
                                      : ""}
                                  </span>
                                </li>
                                <li>
                                  Taken The Acts{" "}
                                  <span>
                                    {this.state.playerAcademicData
                                      .taken_the_acts
                                      ? this.state.playerAcademicData
                                          .taken_the_acts
                                      : ""}
                                  </span>
                                </li>
                                <li>
                                  Taken The Stas{" "}
                                  <span>
                                    {this.state.playerAcademicData
                                      .taken_the_sats
                                      ? this.state.playerAcademicData
                                          .taken_the_sats
                                      : ""}
                                  </span>
                                </li>
                                <li>
                                  School Size <span>650</span>
                                </li>
                                <div className="player_listimage">
                                  <img
                                    src={
                                      this.state.playerimages &&
                                      this.state.playerimages[0]
                                        ? this.state.playerimages[0].file_url
                                        : ""
                                    }
                                  />
                                </div>

                                <li className="coach">Academic Achievements </li>
                                    <li>{this.state.playerAcademicData
                                        .achievement}</li>
                              </div>

                              <div className="col-lg-6 col-md-6">
                                <li className="coach">TRANSCRIPTS</li>

                                {this.state.filedata &&
                                this.state.filedata.length
                                  ? this.state.filedata.map((u, k) => {
                                      if (u.section == "profile_awards") {
                                        return (
                                          <li key={k}>
                                            {u.original_file_name}
                                            <a
                                              href={u.file_url}
                                              target="_blank"
                                              className="download"
                                              download
                                            >
                                              <img src="../../images/download.png" />
                                            </a>
                                          </li>
                                        );
                                      }
                                    })
                                  : null}

                                <li className="coach">REFERENCE</li>
                                {this.state.filedata &&
                                this.state.filedata.length
                                  ? this.state.filedata.map((s, w) => {
                                      if (s.section != "profile_awards") {
                                        return (
                                          <li key={w}>
                                            {s.original_file_name}
                                            <a
                                              href={s.file_url}
                                              target="_blank"
                                              className="download"
                                              download
                                            >
                                              <img src="../../images/download.png" />
                                            </a>
                                          </li>
                                        );
                                      }
                                    })
                                  : null}
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane"
                  id="about"
                  role="tabpanel"
                  aria-labelledby="about-tab"
                >
                  <div className="year_listplayer p-0 mt-2">
                    <div className="baseballPlayer">
                      <h3>ABOUT ME</h3>
                      <div className="player_list">
                        <div className="player_listimage">
                          <img
                            src={
                              this.state.playerimages &&
                              this.state.playerimages[0]
                                ? this.state.playerimages[0].file_url
                                : ""
                            }
                          />
                          <br />
                        </div>
                        <div className="player_listtxt">
                          <div className="aboutContent">{this.state.playeraboutdetails && this.state.playeraboutdetails.field_value ? this.state.playeraboutdetails.field_value:""}</div>
                          <ul>
                            <li>
                              GRADUATION YEAR :
                              <span>
                                {" "}
                                {this.state.playerDetalsData.graduation_year ? this.state.playerDetalsData.graduation_year:""}
                              </span>
                            </li>
                            <li>
                              DATE OF BIRTH :{" "}
                              <span> {moment(this.state.playerDetalsData.dob).format("MM-DD-YYYY")}</span>
                            </li>
                            <li>
                              HEIGHT / WEIGHT :{" "}
                              <span>
                                {" "}
                                {this.state.playerDetalsData.height} /{" "}
                                {this.state.playerDetalsData.weight}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane"
                  id="media"
                  role="tabpanel"
                  aria-labelledby="media-tab"
                >
                  <div className="year_listplayer p-0 mt-2">
                    
                    <h3>MY PHOTOS & VIDEOS</h3>
                    <div className="portfolio-item row">
                      {this.state.playervideo  && this.state.playervideo.length
                        ? this.state.playervideo.map((q, i) => {
                            return (
                              <div
                                className="item selfie col-lg-6 col-6 col-sm-6"
                                key={i}
                              >
                                <iframe
                                  width="100%"
                                  height="100%"
                                  src={q.video_details.video_url}
                                  title={""}
                                  frameborder="0"
                                  allow="autoplay; encrypted-media" allowfullscreen
                                ></iframe>
                              </div>
                            );
                          })
                        : null}

                      {this.state.playerimages && this.state.playerimages.length
                        ? this.state.playerimages.map((q, i) => {
                            return (
                              <div
                                className="item selfie col-lg-3 col-md-4 col-6 col-sm"
                                key={i}
                              >
                               
                                  <img
                                    className="img-fluid"
                                    src={q.field_value_url}
                                    alt=""
                                  />
                               
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane"
                  id="schedule"
                  role="tabpanel"
                  aria-labelledby="schedule-tab"
                >
                  <div className="year_listplayer p-0 mt-2">
                    <div className="schedulePage">
                      <h3 > {this.state.playerDetalsData.game_name
                      ? this.state.playerDetalsData.game_name
                      : "NA"} Schedule</h3>
                      <div className="player_list">
                        <div className="player_listtxt">
                          {this.state.playerSchedule &&
                          this.state.playerSchedule.length
                            ? this.state.playerSchedule.map((q, i) => {
                                return (
                                  <ul>
                                    <li className="coach">SChedule</li>
                                    <li>
                                      season <span>{q.season}</span>
                                    </li>

                                    <li>
                                      Schedule File
                                      <span>
                                        <a href={q.file ? q.file.file_url:"#"} target="_blank" download>{q.file ? q.file.original_file_name:""}</a>
                                      </span>
                                    </li>
                                  </ul>
                                );
                              })
                            : null}

                          <div className="player_listimage">
                          
                            <img
                            src={
                              this.state.playerimages &&
                              this.state.playerimages[0] && this.state.playerimages[0].schedule_page_media_box_1 == 1 
                                ? this.state.playerimages[0].file_url
                                : ""
                            }
                          />
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="viewplayer_btnarea contact_area">
          <div className="container">
            <NavLink strict to={"/contact/"+this.props.match.params.id} className="viewplayer_btn">
              Contact {this.state.playerDetalsData.first_name
                      ? this.state.playerDetalsData.first_name
                      : "NA"}
            </NavLink>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            Copyright © College Athlete Advantage LLC.
          </div>
        </div>
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Playerdetails);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
