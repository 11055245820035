import React from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap';

const Loader = (props) => {
   return (
      <div>
         {props.isLoading && <Modal isOpen={true} className="loader-content">
         
               <div className="preloader text-center">
                 <img src="/images/loading.gif" height="80" width="80"/>
               </div>
            
         </Modal>}
      </div>

   )
}

const mapStateToProps = (state) => {
   return {
      isLoading: state.loader.isLoading
   }
}

export default connect(mapStateToProps)(Loader)