import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import userReducer from './user.reducer';
import loaderReducer from './loaderReducer'

const rootReducer = combineReducers({
    form: formReducer,
    toastr: toastrReducer,
    user: userReducer,
    loader: loaderReducer,
});

export default rootReducer;