import React, { Component } from "react";

import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const errorStyle = {
  fontSize: "12px",
  color: "#f00",
  display: "inline-block",
};
class Header extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div className="fixed-header">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <NavLink to={"/"} className="navbar-brand">
              <img src="/../../images/logo.png" />
            </NavLink>
            <div className="home_icon">
              <NavLink to={"/"}>
                <img src="/../../images/home_icon.png" />
              </NavLink>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="/../../images/menue_icon.png" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink to={"/"} className="nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/player/"} className="nav-link">
                    View Players
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#">
                    Contact Us
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Header);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
