import React, { Component } from "react";
import { SAGA_ACTIONS, ACTIONS, placehold } from "../../config/config";
import { InputField } from "../../common/form-elements/elements";
import {
  required,
  emailValidator,
  textOnly,
  phoneValidator,
  passwordStrength,
  passwordsMatch,
  numbersOnly,
  usZipCodeValidator,
} from "../../common/validators/form-validators";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";

import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import InfiniteScroll from "react-infinite-scroll-component";
import $ from "jquery";
import Header from "./header";
import renderHTML from 'react-render-html'

const errorStyle = {
  fontSize: "12px",
  color: "#f00",
  display: "inline-block",
};
class Contact extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      email:'',
      name:'',
      phone:"",
      msg:"",
      playerimages:"",
      playerDetalsData:"",
      siteConfig:""
    };
  }
  componentDidMount() {

    this.getContactData();
  }
  getContactData =()=>{
    return new Promise((resolve, reject) => {
      let params = {
     
        playerId: this.props.match.params.playerId,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_CONTACT_DATA,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({ playerDetalsData: res.data.data.player_details });
          this.setState({ siteConfig: res.data.data.site_config });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  }
  sendContact = () =>{
console.log(this.state.email);

return new Promise((resolve, reject) => {
  let params = {
    
    full_name: this.state.name ? this.state.name :"",
    phone: this.state.phone ? this.state.phone :"",
    email: this.state.email ? this.state.email : "",
    message: this.state.msg ? this.state.msg :"",
    playerId: this.props.match.params.playerId

  };
  this.props.dispatch({
    type: SAGA_ACTIONS.USER.PLAYER_CONTACT,
    payload: params,
    callbackSuccess: (res) => {

  
      toastr.success('Success', res.data.message);
      this.setState({name:"",phone:"",email:"",msg:""});

      resolve();
    },
    callbackError: (err) => {
      //login error
      console.log("====ppp===>",err.data.message);
      toastr.error(
        "Error",
        err && err.data && err.data.message
          ? err.data.message
          : "There are some thing wrong"
      );
      reject();
    },
  });
});


  }
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <Header />

        <div className="player_Listing playerDetails">
          <div className="container">
            <div className="playerlisting_bg">
              <h3 className="pb-1">Contact</h3>
              <h4>Somnath</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input type="text" className="form-control" value={this.state.name ? this.state.name :""} required onChange={(e)=> this.setState({name:e.target.value})}/>
                  </div>
                  <div className="form-group">
                    <label>Email address</label>
                    <input type="text" className="form-control" value={this.state.email ? this.state.email :""} onChange={(e)=> this.setState({email:e.target.value})} />
                  </div>
                  <div className="form-group">
                    <label>Phone number</label>
                    <input type="text" className="form-control" value={this.state.phone ? this.state.phone: ""} onChange={(e)=> this.setState({phone:e.target.value})}/>
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea className="form-control" onChange={(e)=> this.setState({msg:e.target.value})} value={this.state.msg ?  this.state.msg: ""}></textarea>
                  </div>
                  <button className="viewplayer_btn" onClick={()=>this.sendContact()}>Submit</button>
                </div>
                <div className="col-lg-5 col-md-5 offset-lg-1 offset-md-1">
                  <div className="contact_address">
                    <h4>{this.state.siteConfig.home_page_title} </h4>
                    <div className="player_listtxt">
                      <ul>
                        <li>
                          <img src="../../images/locartion.png" />
                           {this.state.siteConfig.contact_address}
                         
                        </li>
                        <li>
                          <img src="../../images/call.png" />  {this.state.siteConfig.contact_mobile}
                        </li>
                        <li>
                          <a href={this.state.siteConfig.contact_email}>
                            <img src="../../images/mail.png" /> {this.state.siteConfig.contact_email}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="player_listimage">
                      <img src="../../images/player_06.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="copyright">
          <div className="container">
            Copyright © College Athlete Advantage LLC.
          </div>
        </div>
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Contact);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
