import React, { Component } from "react";
import { SAGA_ACTIONS, ACTIONS, placehold } from "../../config/config";
import { InputField } from "../../common/form-elements/elements";
import {
  required,
  emailValidator,
  textOnly,
  phoneValidator,
  passwordStrength,
  passwordsMatch,
  numbersOnly,
  usZipCodeValidator,
} from "../../common/validators/form-validators";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";

import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import InfiniteScroll from "react-infinite-scroll-component";
import $ from "jquery";
import Header from "./header";
import renderHTML from "react-render-html";

const errorStyle = {
  fontSize: "12px",
  color: "#f00",
  display: "inline-block",
};
class Playermicro extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      msg: "",
      playerimages: "",
      playerDetalsData: "",
      siteConfig: "",
      getInterestedPlayerID: localStorage.getItem("selectplayer") ? localStorage.getItem("selectplayer").split(","):""
    };
  }
  componentDidMount() {
    document.body.classList.remove('forfixedheader');
    // alert(this.props.match.params.id);
    this.getPlayerDetails();
  }
  componentWillUnmount(){
    document.body.classList.add('forfixedheader');

  }
  getPlayerDetails = () => {
    return new Promise((resolve, reject) => {
      let params = {
        playerId: this.props.match.params.id,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_DETAILS,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({ playerDetalsData: res.data.data.details });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  selectPlayer =(playerId)=>{
    var arr = [];
    if(localStorage.getItem('selectplayer')){

    
    arr= [localStorage.getItem('selectplayer'), playerId].join(",");
    localStorage.setItem('selectplayer', arr);
    }
    else{
      localStorage.setItem('selectplayer', playerId);
    }
    
    this.getPlayerDetails();
  }
  unselectPlayer =(p)=>{
    
      let arrPlayer = localStorage.getItem('selectplayer').split(",")
      .filter((x) => x !== p)
      .join(",");
      localStorage.setItem('selectplayer', arrPlayer);

    this.getPlayerDetails();
  }
  render() {
    const { handleSubmit, submitting } = this.props;
console.log(this.state.playerDetalsData ? this.state.playerDetalsData.microsite_url : "");
    return (
      <div className="playermicro">
        <iframe src={this.state.playerDetalsData ? this.state.playerDetalsData.microsite_url : ""} fullscreen width="100%" ></iframe>
        
        <NavLink to={"/player/"}><button className="btn btn-info player_microsite01">Back</button></NavLink>
       {localStorage.getItem("selectplayer") && localStorage.getItem("selectplayer").indexOf(this.props.match.params.id) != -1 ? 
            <button className="btn btn-info player_microsite02 btn-active" onClick={(e)=>this.unselectPlayer(this.props.match.params.id)}>Interested</button> : <a href="javascript:void(0)" onClick={(e)=>this.selectPlayer(this.props.match.params.id)}><button className="btn btn-info player_microsite02">Interested</button></a>
       }
        
        {/* {localStorage.getItem("selectplayer") && localStorage.getItem("selectplayer").indexOf(this.props.match.params.id) == -1 && 
        <a href="javascript:void(0)" onClick={(e)=>this.selectPlayer(this.props.match.params.id)}><button className="btn btn-info player_microsite02">Interested</button></a>
        }
        {localStorage.getItem("selectplayer") && localStorage.getItem("selectplayer")== "" && 
        <a href="javascript:void(0)" onClick={(e)=>this.selectPlayer(this.props.match.params.id)}><button className="btn btn-info player_microsite02">Interested</button></a>
        } */}
        
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Playermicro);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
