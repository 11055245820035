import React, { Component, useRef } from "react";
import { SAGA_ACTIONS, ACTIONS, placehold } from "../../config/config";
import { InputField } from "../../common/form-elements/elements";
import {
  required,
  emailValidator,
  textOnly,
  phoneValidator,
  passwordStrength,
  passwordsMatch,
  numbersOnly,
  usZipCodeValidator,
} from "../../common/validators/form-validators";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { NavLink } from "react-router-dom";

import moment from "moment";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

import InfiniteScroll from "react-infinite-scroll-component";
import Header from "./header";
import $ from "jquery";
import debounce from "lodash/debounce";

const errorStyle = {
  fontSize: "12px",
  color: "#f00",
  display: "inline-block",
};
class Interestedplayerlist extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.textInput = React.createRef();

    this.state = {
      ios: false,
      showQrCode: false,
      showModal: false,
      playerList: {},
      curPage: 0,
      years: {},
      positions: {},
      divisions: {},
      searchYear: "",
      total: "",
      searchPosition: "",
      searchDivision: "",
      formDocs: {},
      positionobjectdata: {},
      stateCode: localStorage.getItem("stateCode")
        ? localStorage.getItem("stateCode")
        : "",

      playerMicrositeIds: localStorage.getItem("selectplayer")
        ? localStorage.getItem("selectplayer")
        : "",
      positionobjectdata2: {},
      modal: false,
      coachEmail: "",
      modalinst: localStorage.getItem("dontshowagain") ? false : true,
      weghted_gpa: "",
      getUnweghtedselectValue: "=",
      getWeghtedselectValue: "=",
      unweghted_gpa: localStorage.getItem("unweghtedgpa")
        ? localStorage.getItem("unweghtedgpa")
        : "",
      unweghted_gpa_max: localStorage.getItem("unweghtedgpamax")
        ? localStorage.getItem("unweghtedgpamax")
        : "",
      minHeight: localStorage.getItem("minHeight")
        ? localStorage.getItem("minHeight")
        : "",
      maxHeight: localStorage.getItem("maxHeight")
        ? localStorage.getItem("maxHeight")
        : "",

      minWeight: localStorage.getItem("minWeight")
        ? localStorage.getItem("minWeight")
        : "",
      maxWeight: localStorage.getItem("maxWeight")
        ? localStorage.getItem("maxWeight")
        : "",

      act: localStorage.getItem("act") ? localStorage.getItem("act") : "",
      sat: localStorage.getItem("sat") ? localStorage.getItem("sat") : "",
      major: localStorage.getItem("major") ? localStorage.getItem("major") : "",
      schoolsize: localStorage.getItem("schoolsize")
        ? localStorage.getItem("schoolsize")
        : "",
      slg: localStorage.getItem("slg") ? localStorage.getItem("slg") : "",
      avg: localStorage.getItem("avg") ? localStorage.getItem("avg") : "",
      obp: localStorage.getItem("obp") ? localStorage.getItem("obp") : "",
      time: localStorage.getItem("time") ? localStorage.getItem("time") : "",
      velo: localStorage.getItem("velo") ? localStorage.getItem("velo") : "",

      rh: localStorage.getItem("rh") ? localStorage.getItem("rh") : "",
      lh: localStorage.getItem("lh") ? localStorage.getItem("lh") : "",

      poptime: localStorage.getItem("poptime")
        ? localStorage.getItem("poptime")
        : "",
      strike: localStorage.getItem("strike")
        ? localStorage.getItem("strike")
        : "",
      walk: localStorage.getItem("walk") ? localStorage.getItem("walk") : "",
      era: localStorage.getItem("era") ? localStorage.getItem("era") : "",
      velocity: localStorage.getItem("velocity")
        ? localStorage.getItem("velocity")
        : "",

      setRHP: localStorage.getItem("setRHP")
        ? localStorage.getItem("setRHP")
        : "",
      setLHP: localStorage.getItem("setLHP")
        ? localStorage.getItem("setLHP")
        : "",

      showFilter: false,
    };
  }
  componentDidMount() {
    if (localStorage.getItem("selectplayer")) {
      this.getPlayerList();
    }
  }

  getPlayerList = () => {
    return new Promise((resolve, reject) => {
      var gid = "6";

      let params = {
        gameId: gid,

        per_page: "20",
        year: "",
        stateCode: "",

        position: "",
        division: "",
        jvData: "",
        ip: "",

        getUnweghtedselectValue: "=",
        getWeghtedselectValue: "=",
        unweghted_gpa: "",
        unweghted_gpa_max: "",

        getWeghtedselectValue: "",
        weghted_gpa: "",
        page: 0,
        playerId: localStorage.getItem("selectplayer")
          ? localStorage.getItem("selectplayer")
          : 0,
        unweghted_gpa: "",
        minHeight: "",
        maxHeight: "",
        minWeight: "",
        maxWeight: "",

        avg: "",
        act: "",
        sat: "",
        major: "",
        schoolsize: this.state.schoolsize ? this.state.schoolsize : "",
        slg: this.state.slg ? this.state.slg : "",
        obp: this.state.obp ? this.state.obp : "",
        time: this.state.time ? this.state.time : "",
        velo: this.state.velo ? this.state.velo : "",

        rh: this.state.rh ? this.state.rh : "",
        lh: this.state.lh ? this.state.lh : "",

        poptime: this.state.poptime ? this.state.poptime : "",
        strike: this.state.strike ? this.state.strike : "",
        walk: this.state.walk ? this.state.walk : "",
        era: this.state.era ? this.state.era : "",
        velocity: this.state.velocity ? this.state.velocity : "",

        setRHP: this.state.setRHP ? this.state.setRHP : "",
        setLHP: this.state.setLHP ? this.state.setLHP : "",
        points_per_game: "",
        rebounds_per_game: "",
        steals_per_game: "",
        blocks_per_game: "",
        field_goal: "",
        pt_fg:"",
        assists_per_game:"",
        free_throw:"",

        getWeghtedselectValue: this.state
          .getUnweghtgetWeghtedselectValueedselectValue
          ? this.state.getWeghtedselectValue
          : "",
        weghted_gpa: this.state.weghted_gpa ? this.state.weghted_gpa : "",
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_LIST,
        payload: params,
        callbackSuccess: (res) => {
          this._isMounted = true;

          this.setState({ playerList: res.data.data.players.data });

          this.setState({ curPage: res.data.data.players.current_page });
          this.setState({ total: res.data.data.players.total });

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.Message
              ? err.data.Message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  fetchMoreData = () => {
    document.activeElement.blur();

    if (this._isMounted) {
      return new Promise((resolve, reject) => {
        var gid = "6";

        let params = {
          skip: "0",

          gameId: gid,
          per_page: "20",
          year: this.state.searchYear ? this.state.searchYear : "",
          position: this.state.searchPosition ? this.state.searchPosition : "",
          division: "",
          jvData: "",
          ip: "",

          minHeight: this.state.minHeight ? this.state.minHeight : "",
          maxHeight: this.state.maxHeight ? this.state.maxHeight : "",

          minWeight: this.state.minWeight ? this.state.minWeight : "",
          maxWeight: this.state.maxWeight ? this.state.maxWeight : "",
          stateCode: "",
          getUnweghtedselectValue: "=",
          getWeghtedselectValue: "=",
          unweghted_gpa: "",
          unweghted_gpa_max: "",

          playerId: localStorage.getItem("selectplayer")
            ? localStorage.getItem("selectplayer")
            : 0,
          avg: "",
          act: "",
          sat: "",
          major: "",
          schoolsize: this.state.schoolsize ? this.state.schoolsize : "",
          slg: this.state.slg ? this.state.slg : "",
          obp: this.state.obp ? this.state.obp : "",
          time: this.state.time ? this.state.time : "",
          velo: this.state.velo ? this.state.velo : "",

          rh: this.state.rh ? this.state.rh : "",
          lh: this.state.lh ? this.state.lh : "",
          points_per_game: "",
          rebounds_per_game: "",
          assists_per_game: "",
          steals_per_game: "",
          blocks_per_game: "",
          field_goal: "",
          pt_fg:"",
          free_throw:"",

          poptime: this.state.poptime ? this.state.poptime : "",
          strike: this.state.strike ? this.state.strike : "",
          walk: this.state.walk ? this.state.walk : "",
          era: this.state.era ? this.state.era : "",
          velocity: this.state.velocity ? this.state.velocity : "",

          setRHP: this.state.setRHP ? this.state.setRHP : "",
          setLHP: this.state.setLHP ? this.state.setLHP : "",

          getWeghtedselectValue: this.state
            .getUnweghtgetWeghtedselectValueedselectValue
            ? this.state.getWeghtedselectValue
            : "",
          weghted_gpa: this.state.weghted_gpa ? this.state.weghted_gpa : "",
          page: this.state.curPage + 1,
        };
        this.props.dispatch({
          type: SAGA_ACTIONS.USER.PLAYER_LIST,
          payload: params,
          callbackSuccess: (res) => {
            this.setState({
              playerList: this.state.playerList.concat(
                res.data.data.players.data
              ),
            });

            this.setState({ curPage: res.data.data.players.current_page });

            resolve();
          },
          callbackError: (err) => {
            //login error
            toastr.error(
              "Error",
              err && err.data && err.data.Message
                ? err.data.Message
                : "There are some thing wrong"
            );
            reject();
          },
        });
      });
    }
  };

  selectDivision = (u) => {
    this.setState({ searchDivision: u, curPage: 0 });
  };
  clearData = () => {
    this.setState({
      searchYear: "",
      searchPosition: "",
      searchDivision: "",
      curPage: 0,
      playerMicrositeIds: "",
      positionobjectdata2: {},
      modal: false,
      positionobjectdata: {},
      coachEmail: "",
      formDocs: {},
      unweghted_gpa: "",
      weghted_gpa: "",
      minHeight: "",
      maxHeight: "",

      minWeight: "",
      maxWeight: "",

      act: "",
      sat: "",
      major: "",
      schoolsize: "",
      slg: "",
      avg: "",
      obp: "",
      time: "",
      velo: "",

      rh: "",
      lh: "",
      poptime: "",
      strike: "",
      walk: "",
      era: "",
      velocity: "",

      setRHP: "",
      setLHP: "",
      weight: { min: 0, max: 0 },
      gpa: { min: 0, max: 0 },

      weghted_gpa: localStorage.getItem("unweghtedgpa")
        ? localStorage.getItem("unweghtedgpa")
        : "",
    });
    localStorage.removeItem("selectplayer");

    setTimeout(() => {
      this.getPlayerList();
    }, 600);
  };

  handleChange2 = (e, name) => {
    let s = this.state.formDocs;
    if (e.target.checked) {
      let prevStr = s[name];

      if (!prevStr) {
        s[name] = e.target.value;
      } else {
        s[name] = [prevStr, e.target.value].join(",");
      }
    } else {
      let prevStr = s[name];
      s[name] = prevStr
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");
      this.setState({ formDocs: s });
    }

    var c = this.state.formDocs;
    var v = Object.values(c).join(",");
    setTimeout(() => {
      this.setState({ searchYear: v });

      //this.clearData();
      // this.getPlayerList();
    }, 600);
  };
  handleChange = (e, name) => {
    let s = this.state.positionobjectdata;
    if (e.target.checked) {
      let prevStr = s[name];

      if (!prevStr) {
        s[name] = e.target.value;
      } else {
        s[name] = [prevStr, e.target.value].join(",");
      }
    } else {
      let prevStr = s[name];
      s[name] = prevStr
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");
      this.setState({ positionobjectdata: s });
    }
    var cs = this.state.positionobjectdata;
    console.log("====selected position==", this.state.positionobjectdata);
    var vs = Object.values(cs).join(",");
    setTimeout(() => {
      this.setState({ searchPosition: vs, curPage: 0 });

      //this.clearData();
      // this.getPlayerList();
    }, 600);
  };
  handleChangePlayer = (e, name) => {
    let s = this.state.positionobjectdata2;
    if (e.target.checked) {
      var arr = [];
      if (localStorage.getItem("selectplayer")) {
        arr = [localStorage.getItem("selectplayer"), e.target.value].join(",");

        console.log("==>>>", arr);
        localStorage.setItem("selectplayer", arr);
      } else {
        localStorage.setItem("selectplayer", e.target.value);
      }
    } else {
      let arrPlayer = localStorage
        .getItem("selectplayer")
        .split(",")
        .filter((x) => x !== e.target.value)
        .join(",");

      // let prevStr = s[name];
      // s[name] = prevStr
      //   .split(",")
      //   .filter((x) => x !== e.target.value)
      //   .join(",");
      localStorage.setItem("selectplayer", arrPlayer);
    }
    console.log("===position====>>", localStorage.getItem("selectplayer"));

    setTimeout(() => {
      this.setState({
        playerMicrositeIds: localStorage.getItem("selectplayer"),
      });
    }, 600);
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  sendMicrositeMail = () => {
    return new Promise((resolve, reject) => {
      let params = {
        email: this.state.coachEmail,
        player_id: this.state.playerMicrositeIds,
      };
      this.props.dispatch({
        type: SAGA_ACTIONS.USER.PLAYER_MICROSITE_LIST,
        payload: params,
        callbackSuccess: (res) => {
          this.setState({
            modal: false,
            coachEmail: "",
          });
          toastr.success("Success", res.data.message);

          resolve();
        },
        callbackError: (err) => {
          //login error
          toastr.error(
            "Error",
            err && err.data && err.data.message
              ? err.data.message
              : "There are some thing wrong"
          );
          reject();
        },
      });
    });
  };
  handleFocus = () => {
    this.textInput.current.blur(); // removing focus
  };
  closeInst = () => {
    this.setState({
      modalinst: false,
    });
  };
  getUnweghtedselectValue = debounce((value) => {
    this.setState({ getUnweghtedselectValue: value });
    if (this.state.unweghted_gpa) {
      // this.getPlayerList();
    }
  }, 1500);
  getWeghtedselectValue = debounce((value) => {
    this.setState({ getWeghtedselectValue: value });
    if (this.state.weghted_gpa) {
      // this.getPlayerList();
    }
  }, 1500);
  unweghtedgpa = debounce((value) => {
    this.setState({
      unweghted_gpa: value,
    });
    //this.getPlayerList();
  }, 1500);

  weghtedgpa = debounce((value) => {
    this.setState({
      weghted_gpa: value,
    });

    // this.getPlayerList();
  }, 1500);

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
        <Header />
        <Modal isOpen={this.state.modal} toggle={this.toggle} centered>
          <ModalHeader toggle={this.props.close}>Checkout</ModalHeader>
          <ModalBody>
            <div>
              CAA will email you a list of interested athletes and links to
              their profiles. Need more help email caa support at
              info@collegeathleteadvantage.com
            </div>
            <FormGroup>
              <Label htmlFor="backdrop">
                <b>Email</b>
              </Label>{" "}
              <Input
                type="text"
                name="email"
                id="email"
                onChange={(e) => this.setState({ coachEmail: e.target.value })}
              ></Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => this.sendMicrositeMail()}>
              Send
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <div className="player_Listing">
          <div className="container">
            <div className="playerlisting_bg">
              <div className="header-btn-fixed">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="pb-0">Interested Player List</h3>
                  <div className="btn-seciright">
                    <button
                      className="btn btn-info player_microsite get_checkout"
                      onClick={(e) => this.toggle()}
                    >
                      Checkout
                    </button>

                    <button
                      className="btn btn-info"
                      onClick={(e) => this.props.history.push("/player")}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>

              <div className="divssion_part interestfixedheader">
                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="year_listplayer">
                      <div className="row">
                        <InfiniteScroll
                          dataLength={
                            this.state.playerList &&
                            this.state.playerList.length
                              ? this.state.playerList.length
                              : 0
                          }
                          next={this.fetchMoreData}
                          hasMore={true}
                          pullDownToRefresh
                          pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8595; Pull down to refresh
                            </h3>
                          }
                          releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8593; Release to refresh
                            </h3>
                          }
                          refreshFunction={this.fetchMoreData}
                        >
                          <div className="row">
                            {this.state.playerList &&
                            this.state.playerList.length ? (
                              this.state.playerList.map((k, j) => {
                                const userInitials =
                                  k && k.full_name
                                    ? k.full_name.charAt(0).toUpperCase()
                                    : "";
                                return (
                                  <div className="col-lg-3 col-md-6" key={j}>
                                    <div className="player_list">
                                      <div className="player_listimage">
                                        <div className="player_check">
                                          <input
                                            ref={this.textInput}
                                            checked={
                                              this.state.playerMicrositeIds &&
                                              this.state.playerMicrositeIds
                                                .length &&
                                              this.state.playerMicrositeIds.indexOf(
                                                k.id
                                              ) != -1
                                                ? "checked"
                                                : ""
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                            value={k.id}
                                            name={k.id}
                                            id={"ch" + k.id}
                                            onChange={(e) =>
                                              this.handleChangePlayer(e, k.id)
                                            }
                                          />
                                        </div>
                                        <div className="lbl-new">
                                          <label>Interest</label>
                                        </div>

                                        <NavLink to={"/playermicro/" + k.id}>
                                          <img
                                            className="player_Img"
                                            src={
                                              k.profile_pic_url
                                                ? k.profile_pic_url
                                                : `${placehold}/400x400/?text=${userInitials}`
                                            }
                                            alt={k.full_name}
                                          />
                                        </NavLink>
                                      </div>
                                      <h4 className="player_name">
                                        {k.full_name}
                                      </h4>
                                      <div className="player_listtxt">
                                        <ul>
                                          <li>
                                            Graduation Year :{" "}
                                            <span> {k.graduation_year} </span>
                                          </li>
                                          <li>
                                            Primary Position :
                                            <span>
                                              {k.player_details &&
                                              k.player_details.primary_position
                                                ? k.player_details
                                                    .primary_position
                                                : ""}
                                            </span>
                                          </li>
                                          <li>
                                            High School Name :{" "}
                                            <span>{k.high_school_name}</span>
                                          </li>
                                          <li>
                                            High School City :{" "}
                                            <span>{k.high_school_city}</span>
                                          </li>
                                          <li>
                                            High School State :{" "}
                                            <span>{k.high_school_state}</span>
                                          </li>
                                          <li>
                                            Height/Weight :{" "}
                                            <span>
                                              {k.height} / {k.weight}
                                            </span>
                                          </li>
                                          <li>
                                            R/L Handed :{" "}
                                            <span>
                                              {k.player_details &&
                                              k.player_details.r_l_handed
                                                ? k.player_details.r_l_handed
                                                : ""}
                                            </span>
                                          </li>
                                          <li>
                                            GPA :{" "}
                                            <span>
                                              {k.player_details &&
                                              k.player_details.unweighted_gpa
                                                ? k.player_details
                                                    .unweighted_gpa
                                                : ""}
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="c0l-lg-12 col-md-12">
                                No Records Found!
                              </div>
                            )}
                          </div>
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            Copyright © College Athlete Advantage LLC.
          </div>
        </div>
      </div>
    );
  }
}
const LoginForm = reduxForm({
  form: "loginForm",
})(Interestedplayerlist);
const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userData,
  };
};

export default connect(mapStateToProps)(LoginForm);
