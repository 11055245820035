export const STORAGE = 'eh-ui-auth-key';
export const BASE_URL = 'https://mobileahtletes.com/api/v1';
export const BASE_URL_OTHER = '';
export const placehold = 'https://via.placeholder.com';

export const APIS = {
   USER: {
      LOGIN: `${BASE_URL}/APIAdminLogin/V1/Post`,
      ME: `${BASE_URL}/profile-details`,
      LOGOUT: `${BASE_URL}/logout`,
      REGISTER: {
         REGISTER_USER: `${BASE_URL}/register`,
         GET_OTP: `${BASE_URL}/register-otp`,
         GET_COUNTRY: `${BASE_URL}/get-country`,
         GET_STATE: `${BASE_URL}/get-state`,
         GET_CITY: `${BASE_URL}/get-city`,
      },
      FORGOT_PASSWORD: `${BASE_URL}/forgot-password`,
      RESET_PASSWORD: `${BASE_URL}/reset-password`,
      CHECK_RESET_LINK: `${BASE_URL}/verify-token`,
      UPDATE_PROFILE: `${BASE_URL}/profile-details`,
      CARDS: {
         CARD_LIST: `${BASE_URL}/card-list`,
         CARD_DETAILS: `${BASE_URL}/card-details?id=82`,
         CARD_SAVE: `${BASE_URL}/card-save`,
         CARD_REMOVE: `${BASE_URL}/card-remove`,
         CARD_UPDATE: `${BASE_URL}/card-update`,
      },
      QRCODE_HISTORY: `${BASE_URL}/APIVoucher/V1/GetByVoucherNo`,
      ORDERDETAILS: `${BASE_URL_OTHER}/GetOrderByOrderID`,
      RESTOORDERSUBMIT: `${BASE_URL_OTHER}/OrderStatusChange`,
      CONTACT_US: `${BASE_URL}/contact-request`,
      TRACKING_LIST: `${BASE_URL}/shipment-tracking-list`,
      SEND_TRACKING_ADD: `${BASE_URL}/shipment-tracking-add`,
      FEEDBACK_SEND: `${BASE_URL}/feedback-send`,
      ACTIVE_PLAN: `${BASE_URL}/fetch-active-plan`,
      PURCHASE_PLAN: `${BASE_URL}/APIVoucherRedeem/Post`,
      PLAYER_LIST: `${BASE_URL}/player/wa/players`,
      PLAYER_FILTER_DATA: `${BASE_URL}/player/wa/players/home_page_player_feilds_year`,
      PLAYER_DETAILS: `${BASE_URL}/player/wa/players/details`,
      PLAYER_GAME_DETAILS: `${BASE_URL}/player/wa/players`,
      PLAYER_ABOUT_DETAILS: `${BASE_URL}/player/wa/players`,
      PLAYER_ACADEMIC_DETAILS: `${BASE_URL}/player/wa/players`,
      PLAYER_MEDIA_DETAILS: `${BASE_URL}/player/wa/players`,
      PLAYER_SCHEDULE_DETAILS: `${BASE_URL}/player/wa/players`,
      PLAYER_CONTACT: `${BASE_URL}/player/wa/players`,
      PLAYER_CONTACT_DATA: `${BASE_URL}/player/wa/players`,
      PLAYER_MICROSITE_LIST: `${BASE_URL}/player/wa/players/send/microsite`,


   },

};

export const ACTIONS = {
   USER: {
      LOGIN: 'ACTION/USER/LOGIN',
      ME: 'ACTION/USER/ME',
      LOGOUT: 'ACTION/USER/LOGOUT',
      REGISTER: {
         REGISTER_USER: 'ACTION/USER/REGISTER/REGISTER_USER',
         STEP: 'ACTION/USER/REGISTER/CHANGE_STEP',
         SET_COUNTRY: 'ACTION/USER/REGISTER/SET_COUNTRY',
         SET_STATE: 'ACTION/USER/REGISTER/SET_STATE',
         SET_CITY: 'ACTION/USER/REGISTER/SET_CITY',
      },
      HAS_CARD: 'ACTION/USER/REGISTER/HAS_CARD',
      FORGOT_PASSWORD: 'ACTION/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'ACTION/USER/RESET_PASSWORD'
   },
   LOADER: {
      SHOW_LOADER: 'ACTION/LOADER/SHOW_LOADER',
      HIDE_LOADER: 'ACTION/LOADER/HIDE_LOADER'
   },
   CARRIER: {
      AVAILABLE_BIDS: 'ACTION/CARRIER/AVAILABLE_BIDS',
      TOGGLE_FILTER: 'ACTION/CARRIER/TOGGLE_FILTER',
      FILTER_VALUES: 'ACTION/CARRIER/FILTER_VALUES',
      SET_TAB_NAME: 'ACTION/CARRIER/SET_TAB_NAME',
   }
}

export const SAGA_ACTIONS = {
   USER: {
      LOGIN: 'SAGA_ACTION/USER/LOGIN',
      ME: 'SAGA_ACTION/USER/ME',
      LOGOUT: 'SAGA_ACTION/USER/LOGOUT',
      REGISTER: {
         REGISTER_USER: 'SAGA_ACTION/USER/REGISTER/REGISTER_USER',
         GET_OTP: 'SAGA_ACTION/USER/REGISTER/GET_OTP',
         GET_COUNTRY: 'SAGA_ACTION/USER/REGISTER/GET_COUNTRY',
         GET_STATE: 'SAGA_ACTION/USER/REGISTER/GET_STATE',
         GET_CITY: 'SAGA_ACTION/USER/REGISTER/GET_CITY',
      },
      FORGOT_PASSWORD: 'SAGA_ACTION/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'SAGA_ACTION/USER/RESET_PASSWORD',
      CHECK_RESET_LINK: 'SAGA_ACTION/USER/CHECK_RESET_LINK',
      UPDATE_PROFILE: 'SAGA_ACTION/USER/UPDATE_PROFILE',
      CARDS: {
         CARD_LIST: 'SAGA_ACTION/USER/CARDS/CARD_LIST',
         CARD_DETAILS: 'SAGA_ACTION/USER/CARDS/CARD_DETAILS',
         CARD_SAVE: 'SAGA_ACTION/USER/CARDS/CARD_SAVE',
         CARD_REMOVE: 'SAGA_ACTION/USER/CARDS/CARD_REMOVE',
         CARD_UPDATE: 'SAGA_ACTION/USER/CARDS/CARD_UPDATE',
      },
      QRCODE_HISTORY: 'SAGA_ACTION/USER/QRCODE_HISTORY',
      ORDERDETAILS: 'SAGA_ACTION/USER/ORDERDETAILS',
      RESTOORDERSUBMIT:'SAGA_ACTION/USER/RESTOORDERSUBMIT',
      CONTACT_US: 'SAGA_ACTION/USER/CONTACT_US',
      TRACKING_LIST: 'SAGA_ACTION/USER/TRACKING_LIST',
      SEND_TRACKING_ADD: 'SAGA_ACTION/USER/SEND_TRACKING_ADD',
      FEEDBACK_SEND: 'SAGA_ACTION/USER/FEEDBACK_SEND',
      ACTIVE_PLAN: 'SAGA_ACTIONS/USER/ACTIVE_PLAN',
      PURCHASE_PLAN: 'SAGA_ACTIONS/USER/PURCHASE_PLAN',
      PLAYER_LIST: 'SAGA_ACTION/USER/PLAYER_LIST',
      PLAYER_FILTER_DATA: 'SAGA_ACTION/USER/PLAYER_FILTER_DATA',
      PLAYER_DETAILS: 'SAGA_ACTION/USER/PLAYER_DETAILS',
      PLAYER_GAME_DETAILS: 'SAGA_ACTION/USER/PLAYER_GAME_DETAILS',
      PLAYER_ABOUT_DETAILS:'SAGA_ACTION/USER/PLAYER_ABOUT_DETAILS',
      PLAYER_ACADEMIC_DETAILS: 'SAGA_ACTION/USER/PLAYER_ACADEMIC_DETAILS',
      PLAYER_MEDIA_DETAILS: 'SAGA_ACTION/USER/PLAYER_MEDIA_DETAILS',
      PLAYER_SCHEDULE_DETAILS:  'SAGA_ACTION/USER/PLAYER_SCHEDULE_DETAILS',
      PLAYER_CONTACT: 'SAGA_ACTION/USER/PLAYER_CONTACT',
      PLAYER_CONTACT_DATA: 'SAGA_ACTION/USER/PLAYER_CONTACT_DATA',
      PLAYER_MICROSITE_LIST: 'SAGA_ACTION/USER/PLAYER_MICROSITE_LIST',




   },

}

export const TAB_NAMES = {

}
