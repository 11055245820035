import { takeLatest, all } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../config/config';
import {
   loginUser,
   logout,
   getMe,
   registerUser,
   resetPassword,
   forgotPassword,
   getOTP,
   checkToken,
   getCountry,
   getState,
   getCity,
   updateProfile,
   cardList,
   cardSave,
   cardDetails,
   cardRemove,
   cardUpdate,
   qrHistory,
   orderdetails,
   contactUs,

   activePlan,
   purchasePlan,
   restoorderdetails,
   player_list,
   player_filter_data,
   player_details,
   player_game_details,
   player_about_details,
   player_academic_details,
   player_media_details,
   player_schedule_details,
   player_contact,
   player_contact_data,
   player_microsite_list
} from './user.saga';


export function* sagas() {
   yield all([
      //Auth  
      takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
      takeLatest(SAGA_ACTIONS.USER.LOGOUT, logout),
      takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
      takeLatest(SAGA_ACTIONS.USER.CHECK_RESET_LINK, checkToken),
      takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),

      //Register
      takeLatest(SAGA_ACTIONS.USER.REGISTER.REGISTER_USER, registerUser),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_OTP, getOTP),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_COUNTRY, getCountry),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_STATE, getState),
      takeLatest(SAGA_ACTIONS.USER.REGISTER.GET_CITY, getCity),

      //Profile
      takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateProfile),
      takeLatest(SAGA_ACTIONS.USER.ME, getMe),
      takeLatest(SAGA_ACTIONS.USER.QRCODE_HISTORY, qrHistory),
      takeLatest(SAGA_ACTIONS.USER.ORDERDETAILS, orderdetails),
      takeLatest(SAGA_ACTIONS.USER.RESTOORDERSUBMIT, restoorderdetails),
      takeLatest(SAGA_ACTIONS.USER.CONTACT_US, contactUs),
      takeLatest(SAGA_ACTIONS.USER.ACTIVE_PLAN, activePlan),
      takeLatest(SAGA_ACTIONS.USER.PURCHASE_PLAN, purchasePlan),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_LIST, player_list),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_FILTER_DATA, player_filter_data),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_DETAILS, player_details),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_GAME_DETAILS, player_game_details),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_ABOUT_DETAILS, player_about_details),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_ACADEMIC_DETAILS, player_academic_details),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_MEDIA_DETAILS, player_media_details),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_SCHEDULE_DETAILS, player_schedule_details),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_CONTACT, player_contact),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_CONTACT_DATA, player_contact_data),
      takeLatest(SAGA_ACTIONS.USER.PLAYER_MICROSITE_LIST, player_microsite_list),

      

      //User Credit Cards
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_LIST, cardList),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_DETAILS, cardDetails),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_SAVE, cardSave),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_REMOVE, cardRemove),
      takeLatest(SAGA_ACTIONS.USER.CARDS.CARD_UPDATE, cardUpdate),

   

   ]);
}